.login-container {
  background: #F8F8F8 url("../assets/images/img_bottom_graph.svg") no-repeat scroll left 0 bottom 0;
}

.home-login {
  display: flex;
  max-height: 100%;
  overflow-y: auto;
  min-height: 100vh;
  align-items: center;
  box-sizing: border-box;
  padding: 80px 70px 80px 50px;
  background: url("../assets/images/img_water_mark.svg") no-repeat scroll center bottom 60px;
}

.home-outer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.home-inner-left {
  padding: 0 50px 0 0;
  box-sizing: border-box;
  width: calc(100% - 500px);
}

.home-inner-left h1 {
  font-size: 70px;
  color: #212326;
  font-weight: 700;
  line-height: 96px;
  margin: 45px 0 0 0;
  font-family: "Ubuntu", sans-serif;
}

.home-inner-left h1 span {
  color: #FF6700;
}

.home-inner-form {
  width: 500px;
  padding: 32px;
  border-radius: 16px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid rgba(102, 102, 102, 0.25);
  box-shadow: 0px 274px 110px rgba(0, 0, 0, 0.01), 0px 154px 93px rgba(0, 0, 0, 0.05), 0px 69px 69px rgba(0, 0, 0, 0.09), 0px 17px 38px rgba(0, 0, 0, 0.1);
}

.img_logo_kite {
  text-align: center;
}

.img_logo_kite h2 {
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 15px;
  color: #333333;
  line-height: 48px;
  text-align: center;
}

.home-inner-form form label {
  margin: 0 0 4px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 27px;
}

.home-inner-form form .input-group {
  margin: 0 0 20px;
  position: relative;
}

.home-inner-form form .input-group input {
  width: 100%;
  height: 46px;
  display: block;
  padding: 0 15px;
  font-size: 16px;
  line-height: 46px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid rgb(102 102 102 / 60%);
}

.login-button {
  width: 100%;
  height: 46px;
  display: block;
  padding: 0 15px;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 46px;
  text-align: center;
  border-radius: 8px;
  letter-spacing: 1px;
  background: #FF6700;
  transition: all 0.3s;
  box-sizing: border-box;
  text-transform: uppercase;
  border: 1px solid #FF6700;
}

.login-button.login-button-border {
  color: #FF6700;
  background: #FFFFFF;
}


.login-button:hover {
  color: #FFFFFF;
  letter-spacing: 2px;
  background: #FF6700;
}

.sepration-line {
  margin: 32px 0;
  position: relative;
  border-top: 1px solid rgb(102 102 102 / 25%);
}

.sepration-line span {
  top: 0;
  left: 50%;
  padding: 0;
  width: 60px;
  font-size: 24px;
  font-weight: 700;
  color: #666666;
  line-height: 36px;
  font-style: italic;
  position: absolute;
  text-align: center;
  background: #FFFFFF;
  margin: -18px 0 0 -30px;
  box-sizing: border-box;
}

.form-external-link {
  text-align: center;
}

.form-external-link a {
  font-size: 18px;
  font-weight: 400;
  color: #111111;
  line-height: 27px;
  text-decoration: underline;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}