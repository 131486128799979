@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/* font-family: "Ubuntu", sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

/* font-family: "Poppins", sans-serif; */

html {
  scroll-behavior: smooth;
}

body {
  color: #333333;
  min-width: 360px;
  line-height: 1.6;
  overflow-x: hidden;
  background: #F8F8F8;
  font-family: "Poppins", sans-serif;
}

*,
p,
a,
ul,
li,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
span,
body,
table,
figure {
  margin: 0;
  padding: 0;
}

a,
a:hover {
  transition: all 0.3s;
  color: var(--primary_color);
}

a,
a:hover,
a:focus,
span {
  transition: all 0.3s;
  text-decoration: none;
}

ul,
li,
ol {
  list-style-type: none;
}

input:focus,
select:focus,
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--border_color);
}

input:-internal-autofill-selected {
  background: #FFFFFF;
}

.input-field input::placeholder {
  color: #cbcbcb;
}

select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #FFFFFF;
}

/*! ============================================================ !*/

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: #FF6700;
}

/*! ============================================================ !*/

#wrapper {
  min-width: 360px;
}

/*! ============================================================ !*/

#header-wrapper {
  padding: 16px 20px;
  background: #FFFFFF;
  box-shadow: 0px 161px 64px rgba(0, 0, 0, 0.01), 0px 91px 54px rgba(0, 0, 0, 0.05); 
}

.inner-header {
  display: flex;
}

.header-logo {
  width: 180px;
  height: 54px;
}

.header-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header-navigation {
  display: flex;
  padding: 0 0 0 30px;
  box-sizing: border-box;
  width: calc(100% - 180px);
}

.user-trades {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-trades-list {
  overflow-y: auto;
  padding: 0 30px 0 0;
  width: calc(100% - 200px);
}

.user-trades-list ul {
  display: flex;
  align-items: center;
}

.user-trades-list ul li+li {
  margin: 0 0 0 20px;
}

.user-trades-list ul li h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2C2C2C;
  min-width: 120px;
  line-height: 27px;
}

.user-trades-list ul li h3 {
  font-size: 16px;
  font-weight: 600;
  color: #565656;
  line-height: 24px;
}

.user-trades-list ul li label,
.user-trades-list ul li p {
  display: block;
  color: #36454F;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
}

.user-trades-list ul li p {
  font-size: 14px;
  min-width: 100px;
  line-height: 21px;
}

.user-trades-list ul li p.increase-value,
.groups-head ul li p.increase-value {
  color: #007205 !important;
}

.button-box {
  width: 200px;
  text-align: right;
}

.custom-btn {
  height: 40px;
  min-width: 90px;
  font-size: 12px;
  padding: 0 15px;
  font-weight: 400;
  line-height: 40px;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  background: #36454F;
  color: #FFFFFF !important;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
}

/* ======================================================================= */

.main-dashboard {
  /* height: calc(100vh - 86px); */
}

.accordion .accordion-item,
.accordion .nav-tabs .nav-item {
  border: none;  
  margin: 0 0 10px;
  overflow: hidden;
  border-radius: 8px;
  background: #DAE4F0 !important;
}

.accordion .accordion-item .accordion-header,
.accordion .nav-tabs .nav-item .nav-link {
  border: none;
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 21px;
  position: relative;
  transition: all 0.3s;
  padding: 8px 26px 8px 8px;
  color: #36454F !important;
  background: #DAE4F0 !important;
}

.accordion .accordion-item .accordion-header.collapsed::after,
.accordion .accordion-item .accordion-header::after,
.accordion .nav-tabs .nav-item .nav-link::after {
  top: 15px;
  right: 9px;
  height: 8px;
  width: 12px;
  content: "";
  position: absolute;
  transition: all 0.3s;
  transform: rotate(0deg);
  background: url("../assets/images/img_down_arrow.svg") no-repeat scroll center center / cover;
}

.accordion .nav-tabs .nav-item .nav-link::after {
  top: 13.5px;
  right: 8px;
  transform: rotate(-90deg);
}

.accordion .accordion-item .accordion-header::after {
  transform: rotate(-180deg);
}

.accordion .accordion-item .accordion-collapse {
  padding: 0 8px;
}

.accordion .accordion-item .accordion-collapse .accordion-body {
  border-top: 1px solid #36454F;
}

.accordion .nav-tabs .nav-item .nav-link:hover::after,
.accordion .nav-tabs .nav-item .nav-link.active::after {
  background: url("../assets/images/img_down_arrow_white.svg") no-repeat scroll center center / cover;
}

.accordion .accordion-item .accordion-body {
  padding: 8px 0;
}

.accordion .accordion-item .accordion-body h6 {
  font-size: 12px;
  font-weight: 600;
  color: #36454F;
  line-height: 15px;
}

.accordion .accordion-item .accordion-body h5 {
  font-size: 14px;
  font-weight: 600;
  color: #007205;
  line-height: 22px;
  margin: 2px 0 0 0;
}

.active-list-tabs {
  display: flex;
  align-items: center;
  padding: 12px 10px 12px 160px;
  justify-content: space-between;
}

.dashboard-active-tabs .nav-tabs {
  border: none;
}

.dashboard-active-tabs .nav-tabs .nav-link {
  font-size: 14px;
  cursor: pointer;
  color: #FF6700;
  min-width: 126px;
  font-weight: 600;
  line-height: 18px;
  padding: 8px 10px;
  text-align: center;
  border-radius: 8px;
  background: #FADFCC;
  box-sizing: border-box;
  border: 0.4px solid #FF6700;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
}

.dashboard-active-tabs .nav-tabs li+li {
  margin: 0 0 0 10px;
}

.dashboard-active-tabs .nav-tabs .nav-link:hover,
.dashboard-active-tabs .nav-tabs .nav-link.active {
  color: #FFFFFF !important;
  background: #FF6700 !important;
}

.tab-content {
  /* height: calc(100vh - 150px); */
}

.list-bar-view {
  display: flex;
  align-items: flex-start;
}

.list-bar-view .accordion-view {
  width: 150px;
  overflow-y: auto;
  margin: -64px 0 0 0;
  padding: 10px 8px 0;
  box-sizing: border-box;
  height: calc(100vh - 86px);
}

.list-bar-view-comtent {
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  width: calc(100% - 150px);
  height: calc(100vh - 150px);
  border-top: 1px solid #36454F;
  border-left: 1px solid #36454F;
}

.list-bar-view-comtent > .row > .col-md-6 {
  padding: 0 10px;
}

.tab-content-heading {
  display: flex;
  margin: 0 0 10px;
  padding: 10px 12px;
  border-radius: 12px;
  background: #FFFFFF;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 11px 63px 64px rgba(156, 156, 156, 0.09), 3px 16px 35px rgba(156, 156, 156, 0.1);
}

.tab-content-heading h3 {
  font-size: 16px;
  color: #36454F;
  font-weight: 600;
  line-height: 27px;
}

.tab-content-heading a {
  font-size: 12px;
  padding: 0 12px;
  line-height: 27px;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  background: #36454F;
  color: #FFFFFF !important;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
}

.custom-table {
  height: calc(100vh - 228px);
}

.custom-table table {
  margin: 0 0 6px;
  background: none;
}

.custom-table table thead {
  border-top: 1px solid #36454F;
  border-bottom: 1px solid #36454F;
}

.custom-table table thead tr th,
.groups-data table thead tr th {
  min-width: 90px;
  font-size: 15px;
  color: #36454F;
  font-weight: 600;
  line-height: 16px;
  padding: 12px 8px;
  position: relative;
  background: #f4f4f4;
}

.groups-data table {
  width: 100%;
}

.groups-data table thead tr th {
  background: #FFFFFF;
  font-size: 14px;
  border-top: 1px solid #36454F;
  border-bottom: 1px solid #36454F;
}

.custom-table table tbody tr {
  max-width: 8px 0;
}

.custom-table table tbody tr td,
.groups-data table tbody tr td {
  padding: 0;
  border: none;
  font-size: 14px;
  font-weight: 500;
  background: none;
  position: relative;
  background: #DAE4F0;
  vertical-align: middle;
  border-top: 8px solid #f4f4f4;
}

.groups-data table tbody tr td {
  font-size: 13px;
  vertical-align: top;
  margin: 6px 0 0 0 !important;
  border-top: 4px solid #FFFFFF;
  background: #DAE4F0 !important;
}

.custom-table table tbody tr td:first-child .table-data,
.groups-data table tbody tr td:first-child .table-data {
  border-radius: 8px 0 0 8px;
}

.custom-table table tbody tr td:last-child .table-data,
.groups-data table tbody tr td:last-child .table-data {
  border-radius: 0 8px 8px 0;
}

.custom-table table tbody tr td:first-child,
.groups-data table tbody tr td:first-child,
.custom-table table tbody tr td:last-child,
.groups-data table tbody tr td:last-child  {
  background: none;
}

.groups-data table tbody tr td:first-child {
  border-right: 1px solid #fff;
}

.groups-data table tbody tr td:last-child {
  border-left: 1px solid #fff;
}

.groups-data table tbody tr td:first-child::before,
.groups-data table tbody tr td:first-child::after,
.groups-data table tbody tr td:last-child::before,
.groups-data table tbody tr td:last-child::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 12px;
  height: 12px;
  background: url("../assets/images/img_corner.svg") no-repeat scroll 0 0 / cover
}

.groups-data table tbody tr td:first-child::after {
  top: auto;
  bottom: 0;
  transform: rotate(-90deg);
}

.groups-data table tbody tr td:last-child::before,
.groups-data table tbody tr td:last-child::after {
  left: auto;
  right: -1px;
  transform: rotate(90deg);
}

.groups-data table tbody tr td:last-child::after {
  top: auto;
  bottom: -1px;
  transform: rotate(180deg);
}

.table-data {
  padding: 8px;
  line-height: 26px;
  background: #DAE4F0;
}

.table-data.table-data-trades {
  padding: 6px;
}

.table-data input {
  width: 100%;
  border: none;
  height: 26px;
  padding: 0 6px;
  display: block;
  font-size: 10px;
  color: #595959;
  line-height: 26px;
  border-radius: 4px;
}

.table-data a {
  width: 100%;
  font-size: 10px;
  padding: 0 10px;
  line-height: 26px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  background: #36454F;
  box-sizing: border-box;
  color: #FFFFFF !important;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.06);
}

.groups-data {
  margin: 0 0 20px;
  border-radius: 8px;
  background: #FFFFFF;
}

.groups-head {
  display: flex;
  padding: 12px;
  align-items: center;
}

.groups-head h2 {
  width: 200px;
  font-size: 18px;
  font-weight: 600;
  color: #36454F;
  line-height: 18px;
}

.groups-head ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 200px);
}

.groups-head ul li {
  min-width: 140px;
}
.groups-head ul li label {
  font-size: 10px;
  font-weight: 600;
  color: #36454F;
  line-height: 15px;
}

.groups-head ul li p {
  font-size: 14px;
  font-weight: 600;
  color: #36454F;
  line-height: 21px;
}

.groups-head ul li+li {
  margin: 0 0 0 10px;
}

.table-padding {
  padding: 0 8px 8px;
}

.trade-view-lr {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.trade-view-lr .trade-share .trade-unit {
  height: 16px;
  display: flex;
}

.trade-view-lr .trade-share span {
  color: #FFF;
  padding: 0 8px;
  font-size: 10px;
  line-height: 16px;
  border-radius: 4px;
  display: inline-block;
  /* background: #007205; */
}

.invested-value {
  font-size: 9px;
  /* margin: 0 0 8px; */
  color: #007205;
  line-height: 12px;
}

.trade-view-lr .trade-share span.active-sell {
  background: #A00000;
}

.trade-view-lr .trade-share.trade-right {
  text-align: right;
}

.trade-view-lr .trade-share.trade-right .trade-unit {
  justify-content: flex-end;
}

.share-name {
  margin: 1px 4px;
  font-size: 10px;
  font-weight: 400;
  color: #121212;
  line-height: 15px;
}

.share-name.activate-value {
  color: #007205;
}

.trade-quantity-value {
  /* display: flex; */
  flex-wrap: wrap;
  align-items: flex-start;
}

.trade-quantity-value li {
  font-size: 10px;
  color: #36454F;
  font-weight: 400;
  line-height: 10px;
  margin: 0 0 4px;
}

.trade-quantity-value li+li {
  margin: 0 0 4px 5px;
  padding: 0 0 0 5px;
  border-left: 1px solid #36454F;
}